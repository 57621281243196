import React from 'react'
import {FaQuoteRight} from 'react-icons/fa'

const Testimonials = () => {
  return (
    <>
    <h1 className="main-heading">Testimonials</h1>
    <div className="testimonial-container">
        <div className="test-box">
            <div className="quote-icon-box">
                <FaQuoteRight className='quote-icon'/>
            </div>

            <div className="opinions">
                <p>I don't have words to thank this man, I'm really grateful to have this channel and website in my daily routine. If you're a mere beginner, then you can trust this guy and can put your time into his content. I can assure you that it'll be worth it.</p>
            </div>

            <div className="names">
                <span className='name'>Rahul Ketkar</span>
                <span className='profesion'>Web Developer</span>
            </div>
        </div>
        <div className="test-box">
        <div className="quote-icon-box">
                <FaQuoteRight className='quote-icon'/>
            </div>

            <div className="opinions">
                <p>For everyone who wants to level up their #Coding and #Dev skills - seriously, this channel is for you! Both basic and advanced stacks are covered on this channel, and one can learn according to his skill levels. And the icing on the cake is, everything is available for free.</p>
            </div>

            <div className="names">
                <span className='name'>Virat Patil</span>
                <span className='profesion'>Web Developer</span>
            </div>
        </div>
    </div>
    </>
  )
}

export default Testimonials
