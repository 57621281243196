import React from 'react'
import {Swiper, SwiperSlide} from 'swiper/react';
import { Pagination } from 'swiper';

// import swiper css 
import 'swiper/css'
import 'swiper/css/pagination'

// import images 
import img1 from '../images/design1.png'
import img2 from '../images/design2.png'
import img3 from '../images/design3.png'

const Projects = () => {
  return (
    <>
    <h1 className="main-heading">Our Projects</h1>
    <div className="project-container">
        <Swiper 
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            loop={Infinity}   
            // slidesPerView={3}         
            spaceBetween={50}
            breakpoints={
                {
                    1000: {
                        slidesPerView : 2
                    }
                }
            }
            coverflowEffect={
                {
                    rotate:0,
                    stretch:0,
                    depth:100,
                    modifier:2.5
                }
            }
            pagination = {{el:'.swiper-bullets', clickable:true}}

            modules = {[Pagination]}

        className="swiper-container">
            <SwiperSlide className="swiper-slide">
                <img src={img1} alt="one" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img2} alt="two" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img3} alt="three" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img1} alt="four" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img2} alt="five" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img3} alt="six" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img1} alt="four" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img2} alt="five" />
            </SwiperSlide>
            <SwiperSlide className="swiper-slide">
                <img src={img3} alt="six" />
            </SwiperSlide>
           
           <div className="swiper-bullets">

           </div>
        </Swiper>
    </div>
    </>
  )
}

export default Projects
