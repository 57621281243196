import './App.css';
import Footer from './components/Footer';
import Home from './components/Home';
import Navbar from './components/Navbar';
import Projects from './components/Projects';
import Testimonials from './components/Testimonials';

function App() {
  return (
    <>
      <Navbar />
      <Home />
      <Projects />
      <Testimonials/>
      <Footer/>
    </>
  );
}

export default App;
