import React from 'react'
import Typewriter from 'typewriter-effect'

const Home = () => {
  return (
    <>
      <div className="home-container">

        {/* Left section  */}
        <div className="home-left">

          {/* Home main heading  */}
          <h1>Welcome to<span> TSM<span>Codes</span></span></h1>

          {/* Home running text  */}
            <p className='animated-text'>We are <span><Typewriter className="running-text" options={{ 
              autoStart: true,
              loop: true,
              delay: 20,
              strings: [
                " Web Developer.",
                " Android Developer.",
                " Software Developer.",
                " AI/ML Programmer.",
                " Graphic designer."
              ]
            }} /></span>
            </p>

            {/* Home description */}
            <p className="discription">
              We are developer of all type programming fields like 
              Web Developer, Android Developer, and much more things 
              that we developed for you. We are not only developer we 
              teact programming using online lectures and tutorials.
            </p>

            {/* Home buttons */}
            <div className="home-buttons">
              <button className="btn2 exp">Explore</button>
              <button className="btn2 crt">Create project</button>
            </div>
      </div>
    </div>
    </>
  )
}

export default Home
